import React, { Component } from 'react'
import Layout from "../components/layout"
// import {Link} from 'gatsby'
import { HeaderAnim } from "../components/animscripts/page-header-anim";

import Mailicon from '../images/email-icon.svg'
import MailiconSm from '../images/mail-icon-sm.png'

import EnvelopeF from '../images/envelope/envelope-f.svg'
import EnvelopeB from '../images/envelope/envelope-b.svg'
import EnvelopeM from '../images/envelope/envelope-m.svg'
import EnvelopeBT from '../images/envelope/envelope-bt.svg'

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

export class Contact extends Component {

    state = {
        name: "",
        subject: "",
        email: "",
        message: "",
        messageSent: false
    }
    
    componentDidMount(){
        HeaderAnim();
    }
    toggleMessageSent= () =>{
        this.setState({messageSent : !this.state.messageSent});
    }
    handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...this.state })
          })
            .then(() => this.toggleMessageSent())
            .catch(error => alert(error));
            this.setState({
                name: "",
                subject: "",
                email: "",
                message: "",
            });
        e.preventDefault()
    }
    handleInputChange = e => this.setState({ [e.target.name]: e.target.value });

    


    render() {
        const {name,subject,email,message} = this.state;
        const modalClass = this.state.messageSent ? 'show--modal' : 'hide--modal';
        const mailIconClass = this.state.messageSent ? 'show--icon' : '';
        const modalBg = this.state.messageSent ? 'thankyou__modal__blur--in' : '';
        const modalBt = this.state.messageSent ? 'envelope__svg__bt--in' : '';
        const modalM = this.state.messageSent ? 'envelope__svg__m--in' : '';
        const checkIn = this.state.messageSent ? 'check--in' : '';;

        return (
            <Layout>
            <div className="contact__container">
                        
                <div className="cross__wrapper cross__wrapper__projects__top cross--shape"></div>
                    <h3 className="page__header__txt page__header__txt--top header--anim">Cont<span>act</span></h3>
                    <div className={`thankyou__modal ${modalClass}`}>
                    <svg className={`thankyou__modal__check__mark ${checkIn}`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
  <circle className="path circle" fill="#16c79a" stroke="none" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
  <polyline className="path mark" fill="none" stroke="#fefefe" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
</svg>
                        <div className={`envelope__svg ${mailIconClass}`}>
                            <img className={`envelope__svg__bt ${modalBt}`} src={EnvelopeBT} alt="envelope icon component"></img>
                            <img className="envelope__svg__b" src={EnvelopeB} alt="envelope icon component"></img>
                            <img className={`envelope__svg__m ${modalM}`} src={EnvelopeM} alt="envelope icon component"></img>
                            <img className="envelope__svg__f" src={EnvelopeF} alt="envelope icon component"></img>
                        </div>
                        <div className="thankyou__modal__txt">
                            <h3>Thank you!</h3>
                            <p>Your message has been sent. I will be in touch ASAP.&#128522; 📧</p>
                        </div>
                    <a className="thankyou__modal__back__btn" onClick={this.toggleMessageSent}> <span>&larr;</span> Go back</a>
                    </div>
                <div className={`thankyou__modal__blur ${modalBg}`}></div>

                <form method="post" 
                name="contact"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className="contact__form" 
                onSubmit={ this.handleSubmit }>
                    <div className="contact__form__input__container input--slide--in">
                    <input type="text" 
                    className="contact__form__input contact__form__input--name"
                    name="name" 
                    value={name}
                    onChange={this.handleInputChange} required/>
                    <label htmlFor="fullName" className="label__name">
                        <span className="label__name label__name--txt">Full name</span>
                    </label>
                    </div>
                    <div className="contact__form__input__container input--slide--in">
                    <input type="email" 
                    className="contact__form__input contact__form__input--email"
                    name="email" 
                    value={email}
                    onChange={this.handleInputChange} required/>
                    <label htmlFor="emailAddress" className="label__name">
                        <span className="label__name label__name--txt">Email address</span>
                    </label>
                    </div>
                    <div className="contact__form__input__container input--slide--in">
                    <input type="text" 
                    className="contact__form__input contact__form__input--subject"
                    name="subject" 
                    value={subject}
                    onChange={this.handleInputChange} required/>
                    <label htmlFor="subject" className="label__name">
                        <span className="label__name label__name--txt">Subject</span>
                    </label>
                    </div>
                    <div className="contact__form__input__container input--slide--in">
                    <textarea name="message"
                    className="contact__form__input contact__form__input--message"
                    value={message}
                    onChange={this.handleInputChange} required/>
                    <label htmlFor="message" className="label__name">
                        <span className="label__name label__name--txt label__name--message">Message...</span>
                    </label>
                    </div>
                    
                    
                    <button className="contact__submit__btn cursor-hover">Send<img className="contact__submit__btn__icon" src={MailiconSm} alt="email icon"/></button>
                </form>
                <div className="contact__footer">
                    <div className="contact__footer__or">Or</div>
                    <div className="contact__footer__email">
                        <img className="contact__footer__email__icon" src={Mailicon} alt="email icon"/>
                        <a className="contact__footer__email--link" href="mailto:riwatrj@gmail.com">Riwatrj@gmail.com</a>
                    </div>
                </div>
            </div>
            </Layout>
        )
    }
}

export default Contact

